<template>
  <v-dialog
    v-model="show"
    width="350"
    persistent
  >
  <div v-if="!hired">
    <v-card flat light v-if="!charged">
      <v-card-title class="text-h5">
        <span> HIRE: {{ bid.writer.nickname }} </span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mt-5">
          You are about to assign {{ bid.writer.nickname }} the order with contract total:
        </div>
        <div class="d-flex mt-5">
          <span>${{ parseFloat(bid.amount) }} </span>
          <v-spacer></v-spacer>
          <v-btn :color="essayGreen" dark :disabled="parseInt(insufficientBalance) <= 0" @click="showPayment = true" >DEPOSIT</v-btn>
        </div>
        <div class="mt-5" :class="{'red--text': insufficientBalance !== '0.00'}">
          <span> Insufficient Funds: </span>
          <span> ${{ insufficientBalance }}</span>
        </div>
        <div>
          <span> Your Running Balance </span>
          <span> ${{ runningBalance }}</span>
        </div>
      </v-card-text>
      <v-divider class="mt-10"></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn :color="essayGreen" :disabled="parseInt(insufficientBalance) > 0" @click="hireWriter"  dark :loading="loading_1" > HIRE WRITER</v-btn>
      </v-card-actions>
    </v-card>
    <div v-else>
      <v-card class="pa-0 ma-0 " flat :color="essayGreen" min-height="350px" v-if="successful">
        <v-card-text class="text-center">
          <div class="mt-3">
            <v-icon size="70">mdi-check-circle</v-icon>
            <div>
              <span class="text-h4"> Payment Successful</span>
            </div>
            <div class="mt-3">
              Your Payment has been successful. You can proceed to hire {{ bid.writer.nickname }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="mt-10 mr-5">
          <v-spacer></v-spacer>
          <v-btn class="pa-5 successful" light @click="hireWriter" :loading="loading_1" > Proceed<v-icon>mdi-arrow-right</v-icon></v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="pa-0 ma-0 " flat :color="ant" min-height="350px" v-if="!successful">
        <v-card-text class="text-center">
          <div class="mt-3">
            <v-icon color="red" size="70">mdi-close-circle</v-icon>
            <div class="mt-7">
              <span class="text-h4 red--text"> Payment Failed !</span>
            </div>
            <div class="mt-3">
              Transactions was unsuccessful, Please try again.
            </div>
          </div>
                      
        </v-card-text>
        <v-card-actions class="mt-10">
          <v-spacer></v-spacer>
          <v-btn color="info" @click="charged = false"> Try Again</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

    </div>
  </div>
  <v-card flat light v-else>
      <v-card-title class="text-h5">
        <span> YOU HIRED A WRITER </span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
         <div class="mt-3 text-center">
            <v-icon size="70" :color="essayGreen">mdi-check-circle</v-icon>
            <div>
              <span class="text-h4"> Contract Started!</span>
            </div>
            <div class="mt-3">
              Your contract with {{ bid.writer.nickname }} has Started!
            </div>
          </div>
      </v-card-text>
      <v-divider class="mt-10"></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn :color="essayGreen" class="pa-5" dark @click="proceedContract"> Proceed <v-icon>mdi-arrow-right</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  <PaymentModal 
    :show="showPayment" 
    description="Deposit from Bank card" 
    @close="showPayment=false"
    :minimumAmount="insufficientBalance"
    @callback="callbackMethod"
  />
  </v-dialog> 
</template>

<script>
import { mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: 'HireModal',
  components: {
    PaymentModal: () => import('@/modules/PaymentModal'),
  },
  mixins: [colorMxn,validationMxn],
  props: ['show', 'bid'],
  data () {
    return {
      runningBalance: 0.00,
      showPayment: false,
      charged: false,
      successful: false,
      hired: false,
      loading_1: false,
    }
  },
  computed: {
    ...mapGetters(['getSession']),
    insufficientBalance() {
      const result = this.bid.amount - this.getSession.customer.balance;
      return result <= 0 ? '0.00' : result;
    }
  },
  mounted() {
    this.runningBalance = this.getSession.customer.balance;
  },
  methods: {
    callbackMethod(data) {
      this.showPayment = false;
      this.successful = data.status === "successful";
      this.charged = true;
      this.closePaymentModal();
    },
    closePaymentModal() {
      document.getElementsByName('checkout')[0].setAttribute('style', 'position:fixed;top:0;left:0;z-index:-1;border:none;opacity:0;pointer-events:none;width:100%;height:100%;');
      document.body.style.overflow = '';
    },
    async hireWriter() {
      this.loading_1 = true;
      const payload = {
        bid_status: 'hired',
      };
      const fullPayload = {
        params: payload,
        endpoint: `/bid/update-bid-status/${this.bid.id}`,
      };
      const response = await this.performUpdateActions(fullPayload);
      this.loading_1 = false;
      this.hired = response.data.status === 'success';
    },
    proceedContract() {
      this.$router.push(`/dashboard/order/${this.$route.params.id}`)
    },
  }
}
</script>

<style scoped>
.pending {
  color: #286bb8;
}

.successful {
  color: #038c43;
}

.failed {
  color: red;
}
</style>